<template>
  <div>
    <div class="checkout-container" v-show="isActiveMaintenanceMode">
      <h3>This service is currently undergoing preparation. It will be available to you very soon!</h3>
    </div>
    <div v-show="isActiveMaintenanceMode === false">
      <div class="checkout-container" ref="checkout_container" v-if="innerWidth > 769">
        <div class="checkout-body" ref="checkout_body">
          <upload-raw-file v-if="currentRouteData.step === 'upload' && currentRouteData.productCode === PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA"/>
          <confirm-trait v-if="currentRouteData.step === 'confirm'" :currentRouteData="currentRouteData"/>
        </div>
      </div>
      <div class="checkout-container-mobile"  v-if="innerWidth <= 769">
        <upload-raw-file v-if="currentRouteData.step === 'upload' && currentRouteData.productCode === PRODUCT_CODES.UPLOAD_TRAIT_RAW_DATA"/>
        <account-trait screen="MOBILE" v-if="currentRouteData.step === 'account' && isUserLoggedIn === false" :currentRouteData="currentRouteData"/>
        <confirm-trait screen="MOBILE" v-if="currentRouteData.step === 'confirm'" :currentRouteData="currentRouteData"/>
      </div>
    </div>
  </div>
</template>

<script>
import { PRODUCT_CODES, TRAIT_CHECKOUT_STEPS } from '@/common/config';

import UploadRawFile from './TraitSteps/UploadRawFile.vue';
import AccountTrait from './steps/AccountTrait.vue';
import ConfirmTrait from './TraitSteps/ConfirmTrait.vue';

export default {
  components: {
    UploadRawFile,
    ConfirmTrait,
    AccountTrait,
  },
  data() {
    return {
      isActiveMaintenanceMode: true,
      PRODUCT_CODES,
      TRAIT_CHECKOUT_STEPS,

      currentRouteData: { step: '', productCode: '' },
      selectedProduct: null,

      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
    }
  },
  methods: {
    onSetCurrentRouteData() {
      this.currentRouteData.step = this.$router.currentRoute.params.step;
      if (this.$router.currentRoute.params.product_code) {
        this.currentRouteData.productCode = this.$router.currentRoute.params.product_code;
      }
    },
    onLoadProduct() {
      /* eslint-disable */
      if (this.currentRouteData.step === TRAIT_CHECKOUT_STEPS.UPLOAD) {
        this.$store.dispatch('products/fetchProduct', this.currentRouteData.productCode)
          .then(response => {
            if (response.statusCode === 200) {
              response.result.productPrices.forEach(element => {
                element.label = response.result.product.productType === 1 ? `${element.productCount}` : `${element.productCount}`;
              });
              
              this.$store.commit('traitCheckout/setProductData', response.result);
            } else {
              // AlertService.error(this, response.message)
            }
          });
      }
    },

    adjustHeight() {
      const container = this.$refs.checkout_container;
      const bodyHeight = container.querySelector('.checkout-body').clientHeight;
      container.style.height = bodyHeight > window.innerHeight ? bodyHeight + 50 + 'px' : window.innerHeight + 'px';
    },

    handleBeforeUnload(event) {
      localStorage.setItem('traitCheckout', JSON.stringify(this.$store.state.traitCheckout));
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['account/getIsLoggedIn'];
    },

    windowWidth() {
      return this.$store.state.appConfig.layout.windowWidth;
    },
  },
  mounted() {
    this.onSetCurrentRouteData();
    this.onLoadProduct();

    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";
    window.addEventListener(eventName, this.handleBeforeUnload);

    if (this.$router.currentRoute.query.refCode === '95c3adcf-d7bb-4917-9740-228006ed4fdf') {
      this.isActiveMaintenanceMode = false;
    }

    if (this.innerWidth > 769) {
      const observer = new MutationObserver(this.adjustHeight);
      observer.observe(this.$refs.checkout_body, { attributes: true, childList: true, subtree: true });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);

    const isOnIOS = navigator.userAgent.match(/iPad/i)|| navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? "pagehide" : "beforeunload";
    window.removeEventListener(eventName, this.handleBeforeUnload);
  },
  watch: {    
    $route(to, from) {
      this.onSetCurrentRouteData();
      this.onLoadProduct();
    },
  },
}
</script>

<style lang="scss">
@import "./purchase.scss";
</style>

<style lang="scss" scoped>
.checkout-container {
  padding: 2rem;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.checkout-container-mobile {
  background-color: #fff;
  height: 100%;
  padding: 1rem;
}
</style>
